import React, { useEffect } from "react";
import "./complianceGovernance.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function ComplianceGovernance() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      {" "}
      <Header></Header>
      <div className="governance-banner"></div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 ">
            <h2 style={{ textAlign: "left" }}>
              IT Compliance Audits (GDPR, SOX, HIPAA, PCI DSS)
            </h2>
            <p style={{ textAlign: "left" }}>
              IT Compliance Audits involve evaluating an organization's IT
              systems, policies, and procedures to ensure they meet the
              requirements of specific regulatory frameworks such as GDPR,SOX,
              HIPAA, and PCI DSS.
            </p>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="governance-image1"></div>
          </div> */}
        </div>
      </div>
      <div className="keyActivities-layout">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Regulatory Requirement Analysis</h4>
                  <p>
                    Understand and interpret the specific compliance
                    requirements relevant to the organization (e.g., GDPR,SOX,
                    HIPAA, PCI DSS).
                  </p>
                  <h4>Gap Analysis</h4>
                  <p>
                    Conduct a gap analysis to identify areas where current
                    practices do not meet regulatory standards.
                  </p>
                  <h4>Audit Planning and Scoping</h4>
                  <p>
                    Define the scope of the compliance audit, including the
                    systems, processes, and controls to be evaluated.
                  </p>
                  <h4>Data Collection</h4>
                  <p>
                    Gather relevant data, documentation, and evidence to support
                    the Compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Assessment and Evaluation</h4>
                  <p>
                    Evaluate IT systems, policies, and procedures against
                    regulatory requirements and identify non-compliance issues.
                  </p>
                  <h4>Reporting</h4>
                  <p>
                    Prepare detailed audit reports outlining findings,
                    compliance status, and recommendations for remediation.
                  </p>
                  <h4>Remediation Guidance</h4>
                  <p>
                    Provide guidance and support in addressing identified
                    compliance gaps and implementing corrective actions.
                  </p>
                  <h4>Continuous Monitoring</h4>
                  <p>
                    Establish processes for ongoing compliance monitoring and
                    periodic audits to ensure sustained adherence to regulatory
                    standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Regulatory Compliance</div>
                    <div className="overlay">
                      <p>
                        Ensure adherence to relevant regulations and standards,
                        avoiding legal penalties and fines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Risk Management</div>
                    <div className="overlay">
                      <p>
                        Identify and mitigate compliance risks, enhancing the
                        organization's security posture and protecting sensitive
                        data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Operational Efficiency</div>
                    <div className="overlay">
                      <p>
                        Streamline processes and controls to meet regulatory
                        requirements, improving overall operational efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Trust and Credibility</div>
                    <div className="overlay">
                      <p>
                        Build trust with customers, partners, and stakeholders
                        by demonstrating a commitment to regulatory compliance
                        and data protection.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 ">
            <div className="governance-image2"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 service-text-container6">
            <h2 style={{ textAlign: "left" }}>
              IT Governance Framework Implementation
            </h2>
            <p style={{ textAlign: "left" }}>
              IT Governance Framework Implementation involves establishing and
              maintaining a governance structure that aligns IT strategy with
              business objectives, ensuring effective decision-making, resource
              management, and risk mitigation.
            </p>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Governance Framework Selection</h4>
                  <p>
                    Choose an appropriate IT governance framework (e.g., COBIT,
                    ITIL) based on the organization's needs and industry
                    standards.
                  </p>
                  <h4>Policy Development</h4>
                  <p>
                    Develop and document IT governance policies, procedures, and
                    standards to guide IT operations and decision-making.
                  </p>
                  <h4>Roles and Responsibilities</h4>
                  <p>
                    Define and assign roles and responsibilities for IT
                    governance, including accountability and oversight
                    mechanisms.
                  </p>
                  <h4>Strategic Alignment</h4>
                  <p>
                    Align IT strategy with business goals, ensuring IT
                    initiatives support organizational objectives and deliver
                    value.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Risk Management</h4>
                  <p>
                    Implement risk management processes to identify, assess, and
                    mitigate IT-related risks.
                  </p>
                  <h4>Performance Measurement</h4>
                  <p>
                    Establish metrics and key performance indicators (KPIs) to
                    measure IT performance and governance effectiveness.
                  </p>
                  <h4>Compliance and Reporting</h4>
                  <p>
                    Ensure ongoing compliance with governance policies and
                    standards, and provide regular reports to stakeholders on
                    governance outcomes.
                  </p>
                  <h4>Continuous Improvement</h4>
                  <p>
                    Implement processes for continuous improvement of IT
                    governance practices, incorporating feedback and lessons
                    learned.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Strategic Alignment</div>
                    <div className="overlay">
                      <p>
                        Ensure IT initiatives are aligned with business goals,
                        enhancing the value delivered by IT investments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Effective Decision-Making
                    </div>
                    <div className="overlay">
                      <p>
                        Improve decision-making processes through clear
                        governance structures and accountability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Risk Management</div>
                    <div className="overlay">
                      <p>
                        Mitigate IT-related risks through structured risk
                        management and compliance processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Operational Efficiency</div>
                    <div className="overlay">
                      <p>
                        Enhance operational efficiency and resource management
                        by standardizing IT practices and policies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4 service-text-container">
            <h2 style={{ textAlign: "left" }}>
              Risk Assessment and Management
            </h2>
            <p style={{ textAlign: "left" }}>
              Risk Assessment and Management involves identifying, assessing,
              and mitigating risks associated with IT systems and operations to
              protect the organization from potential threats and
              vulnerabilities.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="governance-image3"></div>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Risk Identification</h4>
                  <p>
                    Identify potential risks to IT systems, data, and
                    operations, including security threats, compliance risks,
                    and operational vulnerabilities.
                  </p>
                  <h4>Risk Assessment</h4>
                  <p>
                    Evaluate the likelihood and impact of identified risks,
                    prioritizing them based on their potential effect on the
                    organization.
                  </p>
                  <h4>Risk Mitigation Planning</h4>
                  <p>
                    Develop and implement strategies to mitigate identified
                    risks, including technical controls, policies, and
                    procedures.
                  </p>
                  <h4>Risk Monitoring</h4>
                  <p>
                    Continuously monitor the risk environment to detect emerging
                    threats and vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Incident Response Planning</h4>
                  <p>
                    Develop and maintain incident response plans to address and
                    recover from security incidents and breaches.
                  </p>
                  <h4>Risk Reporting</h4>
                  <p>
                    Provide regular reports to management and stakeholders on
                    risk assessment findings, mitigation efforts, and risk
                    status.
                  </p>
                  <h4>Training and Awareness</h4>
                  <p>
                    Conduct training and awareness programs to educate employees
                    on risk management practices and their roles in mitigating
                    risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Risk Reduction</div>
                    <div className="overlay">
                      <p>
                        Minimize the likelihood and impact of IT-related risks,
                        enhancing the organization's resilience to threats and
                        vulnerabilities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Compliance Assurance</div>
                    <div className="overlay">
                      <p>
                        Ensure compliance with regulatory requirements and
                        industry standards through proactive risk management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Business Continuity</div>
                    <div className="overlay">
                      <p>
                        Protect business operations and continuity by mitigating
                        risks that could disrupt IT systems and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Informed Decision-Making</div>
                    <div className="overlay">
                      <p>
                        Enable informed decision-making through comprehensive
                        risk assessment and reporting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 service-text-container">
            <h2 style={{ textAlign: "left" }}>
              Regulatory Compliance Consulting
            </h2>
            <p style={{ textAlign: "left" }}>
              Regulatory Compliance Consulting involves providing expert
              guidance and support to help organizations understand and meet
              regulatory requirements, ensuring compliance and minimizing
              regulatory risks.{" "}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="governance-image4"></div>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Regulatory Landscape Analysis</h4>
                  <p>
                    Analyse the regulatory landscape relevant to the
                    organization's industry and operations, identifying
                    applicable regulations and standards.{" "}
                  </p>
                  <h4>Compliance Strategy Development</h4>
                  <p>
                    Develop a compliance strategy and roadmap tailored to the
                    organization's needs, including policies, procedures, and
                    controls.
                  </p>
                  <h4>Implementation Support</h4>
                  <p>
                    Assist in implementing compliance measures, including
                    technology solutions, process changes, and documentation..
                  </p>
                  <h4>Compliance Audits</h4>
                  <p>
                    Conduct internal audits and assessments to evaluate
                    compliance status and identify areas for improvement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Training and Education</h4>
                  <p>
                    Provide training and education to employees on regulatory
                    requirements, compliance practices, and their roles in
                    maintaining compliance.
                  </p>
                  <h4>Ongoing Monitoring and Reporting</h4>
                  <p>
                    Establish processes for ongoing compliance monitoring,
                    reporting, and management to ensure sustained adherence to
                    regulatory requirements.
                  </p>
                  <h4>Third-Party Risk Management</h4>
                  <p>
                    Assess and manage risks associated with third-party vendors
                    and partners to ensure they meet compliance standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Regulatory Compliance</div>
                    <div className="overlay">
                      <p>
                        Achieve and maintain compliance with relevant
                        regulations and standards, avoiding legal penalties and
                        reputational damage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Risk Mitigation</div>
                    <div className="overlay">
                      <p>
                        Identify and mitigate compliance risks, enhancing the
                        organization's security posture and protecting sensitive
                        data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Operational Efficiency</div>
                    <div className="overlay">
                      <p>
                        Streamline compliance processes and controls, improving
                        overall operational efficiency and reducing
                        administrative burden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Enhanced Reputation</div>
                    <div className="overlay">
                      <p>
                        Build trust with customers, partners, and stakeholders
                        by demonstrating a commitment to regulatory compliance
                        and ethical practices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default ComplianceGovernance;
