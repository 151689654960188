import React from "react";
import "./services.css";
import Header from "../header/header";
import Footer from "../footer/footer";
function Services() {
  return (
    <>
      <div className="service-banneerImage ">
        <Header></Header>
        {/* <h6 className="banner-style ">Overview of the professional services</h6> */}
      </div>
    
      <div className=" container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <h1>Our  Virtualization Support Services</h1>
            <p>
              Explore our comprehensive range of  Virtualization Support
              Services designed to meet your business's specific needs and
              requirements.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="Installation-image"></div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-4 col-sm-6 col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">
                     Installation and Configuration
                  </div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Our team of  experts will ensure a smooth and
                      seamless installation and configuration process, tailored
                      to your organization's unique requirements. Whether you're
                      setting up  for the first time or upgrading to the
                      latest version, we've got you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4  col-sm-6  col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">
                    System Optimization and Performance Tuning
                  </div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Maximize the performance and efficiency of your 
                      environment with our system optimization and performance
                      tuning services . Our experts will fine-tune your setup to
                      ensure optimal resource utilization, minimize bottlenecks,
                      and enhance overall system performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4  col-sm-6 col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">
                    Troubleshooting and Issue Resolution
                  </div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Encountering issues with your setup? Our team is
                      here to help. We specialize in quickly identifying and
                      resolving a wide range of issues , from software glitches
                      to hardware failures, to minimize downtime and keep your
                      virtualization environment running smoothly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-4  col-sm-6 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">Upgrade and Migration Assistance</div>
                </div>

                <div class="flip-card-back flip-padding">
                  <div class="content-container">
                    <p>
                      Stay up-to-date with the latest  releases and
                      seamlessly migrate your existing infrastructure with our
                      upgrade and migration assistance services. Whether you're
                      upgrading to a newer version of  or migrating from
                      a different virtualization platform, we'll ensure a
                      hassle-free transition with minimal disruption to your
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-4  col-sm-6 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">Upgrade and Migration Assistance</div>
                </div>

                <div class="flip-card-back flip-padding">
                  <div class="content-container">
                    <p>
                      Stay up-to-date with the latest  releases and
                      seamlessly migrate your existing infrastructure with our
                      upgrade and migration assistance services. Whether you're
                      upgrading to a newer version of  or migrating from
                      a different virtualization platform, we'll ensure a
                      hassle-free transition with minimal disruption to your
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <h1>
              Elevating Your VMware Environment with Professional Services
            </h1>
            <p>
              Experience the power of VMware virtualization like never before
              with our comprehensive professional services. Let us elevate your
              infrastructure for maximum efficiency and performance.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="Installation-image"></div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-4 col-sm-6 col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">Migration Services</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Transitioning to VMware virtualization? Trust our experts
                      to seamlessly migrate your existing infrastructure to
                      VMware's platform. We'll handle the entire migration
                      process, ensuring minimal downtime and maximum efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4  col-sm-6  col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-text">Implementation Services</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Need assistance with deploying VMware solutions in your
                      environment? Our implementation services cover everything
                      from planning and design to installation and
                      configuration, tailored to your unique requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4  col-sm-6 col-12">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=5" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text">Upgrades and Updates</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Stay current with the latest VMware releases and updates
                      with our upgrade services. We'll help you navigate the
                      upgrade process smoothly, ensuring compatibility,
                      performance, and security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4  col-sm-12 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=256" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text">Upgrade and Migration Assistance</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Stay current with the latest VMware releases and updates
                      with our upgrade services. We'll help you navigate the
                      upgrade process smoothly, ensuring compatibility,
                      performance, and security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4  col-sm-12 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=256" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text"> Performance Optimization</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Is your VMware environment not performing as expected? Our
                      team specializes in optimizing VMware environments for
                      peak performance, scalability, and efficiency. We'll
                      conduct a comprehensive assessment and implement targeted
                      optimizations to maximize your ROI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4  col-sm-12 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=256" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text"> Backup and Disaster Recovery</div>
                </div>

                <div class="flip-card-back">
                  <div class="content-container">
                    <p>
                      Safeguard your VMware environment with our backup and
                      disaster recovery solutions. From designing robust backup
                      strategies to implementing disaster recovery plans, we'll
                      ensure your data remains protected and accessible at all
                      times.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-4  col-sm-12 ">
            <div class="flip-card ">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=256" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text">Training and Education</div>
                </div>

                <div class="flip-card-back flip-padding2">
                  <div class="content-container">
                    <p>
                      Empower your team with the knowledge and skills they need
                      to excel in VMware virtualization. Our training and
                      education services cover a wide range of topics, from
                      basic concepts to advanced techniques, delivered by
                      certified VMware professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-4  col-sm-12 ">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  {/* <img src="https://picsum.photos/400/400?random=256" alt="Random Image" class="img-fluid w-100 h-100"> */}
                  <div class="flip-text">Ongoing Support</div>
                </div>

                <div class="flip-card-back flip-padding2">
                  <div class="content-container">
                    <p>
                      Rely on our dedicated support team to keep your VMware
                      environment running smoothly. With proactive monitoring,
                      troubleshooting, and assistance, we'll address any issues
                      quickly and efficiently, minimizing downtime and
                      disruptions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default Services;
