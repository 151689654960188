import React, {  useEffect } from "react";
import "./operationAndSupport.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function OperationAndSupport() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const goToMonitoring = () => {
    navigate("/services/operation-support/monitoring-management");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToCollaboration = () => {
    navigate("/services/operation-support/collaboration-communition");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToCompliance = () => {
    navigate("/services/operation-support/collaboration-communition");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {" "}
      <Header></Header>
      <div className="operationSubpport-banner ">
        {/* <h6 className="operationSubpport-style ">
          OPERATION AND SUPPORT SERVICES
        </h6> */}
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 service-text-container3">
            <h2>OPERATION AND SUPPORT SERVICES</h2>
            <p>
              We excel in providing comprehensive Operation and Support Services
              tailored to meet the diverse needs of modern enterprises. Our
              commitment to excellence and deep industry expertise ensures that
              your infrastructure operates at peak efficiency, allowing you to
              focus on driving business growth.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="operationSubpport-image1"></div>
          </div>
        </div>
      </div>
      <div className="keyFactors-layout  mt-5">
        <div className="container">
          <div className="row">
            <h2>Explore our range of Operation and Support Services:</h2>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
              <p>
               1. 24/7 Monitoring and Maintenance: Proactive monitoring of your
                infrastructure to identify and address issues before they impact
                your operations.
              </p>
              <p>
                2.Incident Management: Rapid response and resolution to incidents
                to minimize downtime and disruption to your business.
              </p>
              <p>
                3.Performance Tuning: Fine-tuning of your infrastructure to ensure
                optimal performance and resource utilization.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
              <p>
                {" "}
                4.Patch Management: Regular updates and patches to keep your
                systems secure and up-to-date.
              </p>
              <p>
                {" "}
                5.Capacity Planning: Strategic planning to ensure your
                infrastructure scales effectively with your business needs.
              </p>
              <p>
                6.Vendor Management: Coordination and management of third-party
                vendors to streamline support and service delivery.
              </p>
            </div>
          </div>
        </div>
      </div>
    
     <div></div>  
     <div className="background-overlay">
        <div className="container mt-5">
          <h4>
            Transform your IT landscape with Nilaware IT's expert virtualization
            consulting, and unlock the full potential of your infrastructure.
          </h4>
        </div>
      </div>
      <div className="container mt-5">
        <div className="traineeship3">
          <div className="process-row">
            <div className="activity animate-from-bottom__0">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Monitoring & Management Services</div>
                </div>
                <div className="active">
                  <div className="title">Monitoring & Management Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;IT
                      Infrastructure Monitoring and Alerting
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Performance
                      Monitoring and Optimization
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Capacity
                      Planning and Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp; IT Service
                      Desk and Help Desk Support
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToMonitoring()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__1">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Collaboration <br></br>Services</div>
                </div>
                <div className="active">
                  <div className="title">Collaboration Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Unified
                      Communications (VoIP, Video Conferencing)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Email and
                      Messaging Solutions
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                      &nbsp;Collaboration Tools Implementation
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToCollaboration()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__2">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Compliance & Governance Services</div>
                </div>
                <div className="active">
                  <div className="title">Compliance & Governance Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp; IT
                      Compliance Audits (GDPR, HIPAA, PCI DSS)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;IT
                      Governance Framework Implementation
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Risk
                      Assessment and Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp; Regulatory
                      Compliance Consulting
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToCompliance()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="process-row">
            {/* <div className="activity animate-from-bottom__3">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Vendor Selection</div>
                </div>
                <div className="active">
                  <div className="title">Vendor Selection</div>
                  <div className="sub-title">
                    Guide you in choosing the best-fit virtualization platform
                    (RedHat, Nutanix) to optimize your operations.
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="activity animate-from-bottom__4">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Implementation Roadmap</div>
                </div>
                <div className="active">
                  <div className="title">Implementation Roadmap</div>
                  <div className="sub-title">
                    Deliver a detailed roadmap for a smooth and successful
                    transition to a virtualized environment.
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default OperationAndSupport;
