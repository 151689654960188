import React, { useEffect } from "react";
import "./proxmox.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function Proxmox() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="solutionproxmax-banner "></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="solutionproxmax-image"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <h2 className="header-style">
              Open-Source Virtualization with Proxmox
            </h2>
            <p style={{ textAlign: "left" }}>
              Empower your IT infrastructure with Proxmox Virtual Environment,
              an open-source virtualization solution.
            </p>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <h2 style={{ textAlign: "left" }}>
          {" "}
          Nilaware IT provides expert services to deploy and manage Proxmox,
          offering.
        </h2>
        <div className="row mt-5 mb-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Cost-Effective Solutions</h3>
              <p>
                Benefit from the affordability and flexibility of open-source
                software.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Versatility</h3>
              <p>
                Run both KVM-based virtual machines and LXC containers on a
                single platform.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Advanced Features</h3>
              <p>
                Leverage features like live migration, high availability, and
                backup solutions.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-4">
            <div class="service-card">
              <h3>User-Friendly Interface</h3>
              <p>
                Simplify management with Proxmox’s intuitive web-based
                interface.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-4">
            <div class="service-card">
              <h3>Comprehensive Support</h3>
              <p>
                Receive ongoing support and maintenance to keep your Proxmox
                environment optimized.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-4">
            <div class="service-card">
              <h3> Integration Capabilities</h3>
              <p>
                Offers seamless integration within the Proxmox environment,
                ensuring robust compatibility and streamlined management of
                virtualized infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="background-overlay">
        <div className="container mt-5">
          <h4>
            Experience the power and flexibility of Proxmox with Nilaware IT’s
            tailored solutions.
          </h4>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default Proxmox;
