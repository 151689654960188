import React, { useEffect } from "react";
import "./vmware.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function VmWare() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="solutionVmware-banner"></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 ">
            <h2 className="header-style">
              Professional Services for VMware Omnissa Products
            </h2>
            <p style={{ textAlign: "left" }}>
              Workspace ONE® Unified Endpoint Management™
            </p>
            <p style={{ textAlign: "left" }}>
              Comprehensive Endpoint Management with Workspace ONE® Unified
              Endpoint Management™
            </p>
            <p style={{ textAlign: "left" }}>
              We help you deliver and manage any application on any device,
              anywhere, with VMware’s Workspace ONE® Unified Endpoint
              Management™. Our professional services include
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="vmwaredecription-image"></div>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <h2 style={{ textAlign: "left" }}>
          {" "}
          {/* Ensuring your business benefits from */}
        </h2>
        <div className="row mt-5 mb-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Deployment and Configuration</h3>
              <p>
                Expert setup and configuration of Workspace ONE UEM to manage
                all your endpoints seamlessly.<br></br>
                Integration with your existing IT infrastructure for a smooth
                transition.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Device Enrollment</h3>
              <p>
                Simplified and secure Enrollment processes for all devices,
                including mobile, desktops, and rugged devices.<br></br>
                Customized Enrollment workflows to meet your specific business
                needs.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Security</h3>
              <p>
                Implement robust security measures to protect your virtual
                machines and data.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>App Management</h3>
              <p>
                Centralized application management for deploying, updating, and
                managing applications across all devices.<br></br>
                Support for native, web, and virtual applications.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>Support</h3>
              <p>
                Access expert support and maintenance services to ensure your
                environment runs smoothly.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3> Integration Capabilities</h3>
              <p>
                Facilitates seamless integration within VMware's Omnissa EUC
                ecosystem, optimizing user experience and operational
                efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div div className="background-overlay">
        <div className=" container ">
          <div className="col-12 mt-5">
            <h3>
              Leverage the full potential of VMware (Broadcom) deployment and
              management services
            </h3>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
export default VmWare;
