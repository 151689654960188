import React, { useEffect } from "react";
import "./coreInfrastructure.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import servicesData from "../data/data.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function CoreInfrastructure() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const goToNetworkservice = () => {
    navigate("/services/core-infrastructure/network-services");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToServerStorage = () => {
    navigate("/services/core-infrastructure/storage-services");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToVirtualizationServices = () => {
    navigate("/services/core-infrastructure/virtualization-services");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToCloudServices = () => {
    navigate("/services/core-infrastructure/cloud-services");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {" "}
      <Header></Header>
      <div className="coreinfra-banner">
        {/* <h6 className="coreinfra-style">CORE INFRASTRUCTURE SERVICES</h6> */}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 core-text-container">
            <h2 style={{ textAlign: "left" }}>
              {" "}
              {
                servicesData.service.coreInfrastructureContent[0]
                  .coreInfrastructureHeader
              }
            </h2>
            <br></br>
            <p style={{ textAlign: "left" }}>
              {
                servicesData.service.coreInfrastructureContent[0]
                  .coreInfrastructureParagraph
              }{" "}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="coreinfraIntro-image"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="traineeship">
          <div className="process-row">
            <div className="activity animate-from-bottom__0">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">
                    Network <br></br> Services
                  </div>
                </div>
                <div className="active">
                  <div className="title">Network Design and Architecture</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Network
                      Design and Architecture
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Network
                      Implementation and Deployment
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Network
                      Monitoring and Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Network
                      Security Assessments
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToNetworkservice()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__1">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Server & Storage Services</div>
                </div>
                <div className="active">
                  <div className="title">Server & Storage Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Server
                      Virtualization
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Storage
                      Virtualization
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;SAN/NAS
                      Implementation and Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Backup and
                      Disaster Recovery Solutions
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToServerStorage()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__2">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">
                    Server Virtualization <br></br>Services
                  </div>
                </div>
                <div className="active">
                  <div className="title"> Server Virtualization Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                      &nbsp;Virtualization Migration
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Desktop
                      Virtualization (VDI, DaaS)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Server
                      Virtualization
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Application
                      Virtualization
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} />
                      &nbsp;Virtualization Security and Compliance
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToVirtualizationServices()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__4">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">
                    Cloud <br></br> Services
                  </div>
                </div>
                <div className="active">
                  <div className="title">Cloud Services</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Public,
                      Private, Hybrid Cloud Design
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Cloud
                      Migration Services
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Cloud
                      Optimization and Cost Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Cloud
                      Security Assessments
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToCloudServices()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="process-row">
            {/* <div className="activity animate-from-bottom__3">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Vendor Selection</div>
                </div>
                <div className="active">
                  <div className="title">Vendor Selection</div>
                  <div className="sub-title">
                    Guide you in choosing the best-fit virtualization platform
                    (RedHat, Nutanix) to optimize your operations.
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="activity animate-from-bottom__4">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Implementation Roadmap</div>
                </div>
                <div className="active">
                  <div className="title">Implementation Roadmap</div>
                  <div className="sub-title">
                    Deliver a detailed roadmap for a smooth and successful
                    transition to a virtualized environment.
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="background-overlay">
        <div className="container mt-5 mb-5">
          <h4>
            Transform your IT landscape with our expert Infrastructure Services
            and unlock the full potential of your IT Environment.
          </h4>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default CoreInfrastructure;
