import React , {  useEffect } from "react";
import "./caseStudies.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function CaseStudies() {
    const location = useLocation();
    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return(
        <>
        <Header></Header>
        <div className="caseStudies-image"></div>
        <Footer></Footer>
        </>
    );


}
export default CaseStudies;