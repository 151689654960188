import React , {  useEffect }from "react";
import "./eBook.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function EBook() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
    return(
        <>
        <Header></Header>
        <div className="eBook-image">
          {" "}
          {/* <h6 className="whitePapers-bannerStyle ">E Book</h6> */}
        </div>
        <Footer></Footer>
      </>
    );


}
export default EBook;