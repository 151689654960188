import React, { useEffect } from "react";
import "./faq.css";
import Application from "../accordion/accordion";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function FAQ() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <div>
        <Header></Header>
        <div className="FAQs-image"></div>
        <div className="col-12">
          <div className="container mt-5">
            <Application></Application>
            <h2 style={{ textAlign: "left" }}>
              For any other questions, Please Contact Us
            </h2>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default FAQ;
