import React from "react";
import "./homeslider.css";
import Slider from "react-slick";
// import contactVideo from "../assets/drive-001/endUserBanner.mp4";
function Homeslider() {
  const settings = {
    dots: false,
    speed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="bannerImage1">
            {/* <h6 className="banner-style ">
              Welcome to Nilaware <br></br>
              <br></br>
              Your Trusted Partner in IT Infrastructure Management
            </h6> */}
            {/* <h6 className="banner-style-5 ">
           
          
            </h6>
            <h6 className="banner-style-core"> </h6> */}
          </div>
        </div>
        <div>
          <div className="bannerImage2">
            {" "}
            <div className=" title-c8g slideText2">
              <h6 className="banner-style2 ">
                Network Services <br></br> <br></br>
                Server & Storage Services <br></br>
                <br></br>
                Virtualization Services
                <br></br>
                <br></br>
                Cloud Services
              </h6>{" "}
            </div>
          </div>
        </div>
        <div>
          <div className="bannerImage3">
            <div className="title-3 slideText2">
              <h6 className="banner-style3 ">
                Monitoring & Management Services
                <br></br>
                <br></br>
                Collaboration Services <br></br>
                <br></br>
                Compliance & Governance Services
                <br></br>
              </h6>
            </div>{" "}
          </div>
        </div>
        <div>
          <div className="bannerImage4">
            <div className="slideText1 title-4 ">
              <h6 className=" banner-style4  ">
                End User Computing <br></br>
                <br></br>
                Mobile Device Management
              </h6>{" "}
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
export default Homeslider;
