import React, { useEffect } from "react";
import "./endUserComputing.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function EnduserComputing() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const goToEUC = () => {
    navigate("/services/end-user-computing/desktop-Virtualization");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToMDM = () => {
    navigate("/services/end-user-computing/moblie-device-management");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {" "}
      <Header></Header>
      <div className="endUser-bannerImage "></div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4 service-text-container">
            <h2>END USER COMPUTNG SERVICES</h2>
            <p>
              We specialize in transforming your IT infrastructure through
              expert virtualization consulting. Our seasoned consultants provide
              comprehensive assessments and strategic planning to maximize your
              IT efficiency and performance
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="endUser-image "></div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        {" "}
        <div className=" mt-5">
          <div className="row">
            <div className="col-12">
              <div className="container-rwt overflow-wxy">
                <div className="carousel mt-5">
                  <div
                    className="slides  testimonial-slide"
                    style={{ left: 0, transition: "left 0.5s ease" }}
                  >
                    <div className="slide testimonial-container">
                      <div className="">
                        <span>
                          {" "}
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="testimonial-icon"
                          />
                        </span>
                        <div className="testimonial-content">
                          <h3>EUC</h3>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Virtual Desktop Infrastructure (VDI)</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Endpoint Device Management</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Application Virtualization for End Users</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;User Experience Monitoring</li>
                        </div>
                          <button className="button-style mt-3">Read More </button>
                      </div>
                    </div>
                    <div className="slide testimonial-container">
                      <div className="">
                        <span>
                          {" "}
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="testimonial-icon"
                          />
                        </span>
                        <div className="testimonial-content">
                          <h3>MDM</h3>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Mobile Device Management (MDM)</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp; Device Enrollment and Provisioning</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp; Configuration Management</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Security Management</li>
                         <li><FontAwesomeIcon icon={faArrowRight} /> &nbsp;Application Management</li>
                        </div>
                        <button className="button-style mt-3">Read More </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="traineeship2">
          <div className="process-row">
            <div className="activity animate-from-bottom__6">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">Desktop Virtualization</div>
                </div>
                <div className="active">
                  <div className="title">Desktop Virtualization</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Virtual
                      Desktop Infrastructure (VDI)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Endpoint
                      Device Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Application
                      Virtualization for End Users
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;User
                      Experience Monitoring
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToEUC()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="activity animate-from-bottom__7">
              <div className="relative-block">
                <div className="inactive">
                  <div className="title">MDM</div>
                </div>
                <div className="active">
                  <div className="title">MDM</div>
                  <div className="sub-title">
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Mobile
                      Device Management (MDM)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp; Device
                      Enrollment and Provisioning
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;
                      Configuration Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Security
                      Management
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faArrowRight} /> &nbsp;Application
                      Management
                    </li>
                  </div>
                  <button
                    className="button-style mt-3"
                    onClick={() => goToMDM()}
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-overlay">
        <div className="container mt-5">
          <h4>
            Transform your IT landscape with Nilaware IT's expert virtualization
            consulting, and unlock the full potential of your infrastructure.
          </h4>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default EnduserComputing;
