import React, { useState, useEffect } from "react";
import "./homeintro.css";
import HomeIntroData from "../data/data.json";
function HomeIntro() {
  const [selectedCarousel, setSelectedCarousel] = useState(null);

  const handleIconClick = (carousel) => {
    setSelectedCarousel(carousel);
  };
  useEffect(() => {
    setSelectedCarousel("carousel1");
  }, []);
  return (
    <div className="container-description mt-5">
      <div className="col-12  ">
        <h2>Overcoming Your IT Challenges</h2>
        <p>
          In today's fast-paced and technology-driven world, organizations face
          numerous IT challenges.
          We understand these hurdles and are here to help you overcome them:
        </p>
      </div>
      <div className="hr-h1"></div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className=" row">
            <div className=" col-sm-12 col-md-6 col-lg-3">
              {" "}
              <button
                className={`description-sideHeader ${
                  selectedCarousel === "carousel1" ? "active" : ""
                }`}
                onClick={() => handleIconClick("carousel1")}
              >
                {
                  HomeIntroData.homedescription.homeDescriptionContent[0]
                    .descriptionHeader
                }
              </button>
            </div>
            <div className=" col-sm-12 col-md-6 col-lg-3">
              <button
                className={`description-sideHeader ${
                  selectedCarousel === "carousel2" ? "active" : ""
                }`}
                onClick={() => handleIconClick("carousel2")}
              >
                {
                  HomeIntroData.homedescription.homeDescriptionContent[1]
                    .descriptionHeader
                }
              </button>
            </div>
            <div className=" col-sm-12 col-md-6 col-lg-3">
              <button
                className={`description-sideHeader ${
                  selectedCarousel === "carousel3" ? "active" : ""
                }`}
                onClick={() => handleIconClick("carousel3")}
              >
                {
                  HomeIntroData.homedescription.homeDescriptionContent[2]
                    .descriptionHeader
                }
              </button>
            </div>
            <div className=" col-sm-12 col-md-6 col-lg-3">
              <button
                className={`description-sideHeader ${
                  selectedCarousel === "carousel4" ? "active" : ""
                }`}
                onClick={() => handleIconClick("carousel4")}
              >
                {
                  HomeIntroData.homedescription.homeDescriptionContent[3]
                    .descriptionHeader
                }
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="image-container">
            {selectedCarousel === "carousel1" && <Carousel1 />}
            {selectedCarousel === "carousel2" && <Carousel2 />}
            {selectedCarousel === "carousel3" && <Carousel3 />}
            {selectedCarousel === "carousel4" && <Carousel4 />}
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomeIntro;
function Carousel1() {
  return (
    <div>
      <div className="descrption-style slideanimleft slideleft">
        <h2>
          {
            HomeIntroData.homedescription.homeDescriptionContent[0]
              .descriptionHeader
          }
        </h2>
        <h4>
          {
            HomeIntroData.homedescription.homeDescriptionContent[0]
              .descriptionParagraph
          }
        </h4>
      </div>
    </div>
  );
}

function Carousel2() {
  return (
    <div>
      <div className="descrption-style slideanimleft slideleft">
        <h2>
          {
            HomeIntroData.homedescription.homeDescriptionContent[1]
              .descriptionHeader
          }
        </h2>
        <h4>
          {
            HomeIntroData.homedescription.homeDescriptionContent[1]
              .descriptionParagraph
          }
        </h4>
      </div>
    </div>
  );
}

function Carousel3() {
  return (
    <div>
      <div className="descrption-style slideanimleft slideleft">
        <h2>
          {
            HomeIntroData.homedescription.homeDescriptionContent[2]
              .descriptionHeader
          }
        </h2>
        <h4>
          {
            HomeIntroData.homedescription.homeDescriptionContent[2]
              .descriptionParagraph
          }
        </h4>
      </div>
    </div>
  );
}

function Carousel4() {
  return (
    <div>
      <div>
        <div className="descrption-style slideanimleft slideleft">
          <h2>
            {
              HomeIntroData.homedescription.homeDescriptionContent[3]
                .descriptionHeader
            }
          </h2>
          <h4>
            {
              HomeIntroData.homedescription.homeDescriptionContent[3]
                .descriptionParagraph
            }
          </h4>
        </div>
      </div>
    </div>
  );
}
