import React, {  useEffect } from "react";
import "./about.css";
import Header from "../header/header";
import Footer from "../footer/footer";
// import aboutusVideo from "../assets/drive-001/aboutUs-banner-video.mp4";
import AboutUsData from "../data/data.json";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function About() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      {" "}
      <Header></Header>
      <div className="aboutus-bannerimage"></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            {/* <h2 className="header-style">
              {AboutUsData.aboutUs.content[0].descriptionHeader}
            </h2> */}
            <p className="aboutus-paraStyle">
              {AboutUsData.aboutUs.content[0].descriptionParagraph}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="aboutusIntro-image"></div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="infrastucture-image"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <h2 className="aboutus-header-style ">
              {AboutUsData.aboutUs.content[1].supportPartnerheader}
            </h2>
            <p style={{ textAlign: "left" }}>
              {AboutUsData.aboutUs.content[1].supportpartnerParagraph}
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <h2 className="header-style">
              {AboutUsData.aboutUs.content[2].ourStroyHeader}
            </h2>
            <p style={{ textAlign: "left" }}>
              {AboutUsData.aboutUs.content[2].ourStoryParagraph}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="ourStory-image"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="ourStory-image2"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <p style={{ textAlign: "left" }}>
              {AboutUsData.aboutUs.content[3].ourStoryParagraph}
            </p>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <p style={{ textAlign: "left" }}>
              {AboutUsData.aboutUs.content[4].ourStoryParagraph}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="ourStory-image3"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="ourStory-image4"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <p style={{ textAlign: "left" }}>
              {AboutUsData.aboutUs.content[5].ourStoryParagraph}
            </p>
          </div>
        </div>
      </div>
      <div className="ourMission">
        <div className="container">
          <h2>{AboutUsData.aboutUs.content[6].ourMissonHeader}</h2>

          <p>{AboutUsData.aboutUs.content[6].ourMissonParagraph}</p>
          <p>{AboutUsData.aboutUs.content[7].ourMissonParagraph}</p>
          <p>{AboutUsData.aboutUs.content[8].ourMissonParagraph}</p>
        </div>
      </div>
      <div className="ourvalues">
        <div className="container ">
          <div className=" content">
            <h2>Our Values</h2>
            <h4>
              We are guided by a set of core values that shape everything we do
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3 ">
                <div className=" box">
                  <div className="inner">
                    <h3>{AboutUsData.aboutUs.content[9].ourValuesHeader1}</h3>
                    <p>{AboutUsData.aboutUs.content[9].ourValuesParagraph1} </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className=" box">
                  <div className="inner">
                    <h3>{AboutUsData.aboutUs.content[10].ourValuesHeader2}</h3>
                    <p>
                      {AboutUsData.aboutUs.content[10].ourValuesParagraph2}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className=" box">
                  <div className="inner">
                    <h3>{AboutUsData.aboutUs.content[11].ourValuesHeader3}</h3>
                    <p>
                      {AboutUsData.aboutUs.content[11].ourValuesParagraph3}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className=" box">
                  <div className="inner">
                    <h3>{AboutUsData.aboutUs.content[12].ourValuesHeader4}</h3>
                    <p>
                      {AboutUsData.aboutUs.content[12].ourValuesParagraph4}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
      <div className="ourTeam ">
        <div className="container">
          <div className="ourteam-margin">
            <h2>{AboutUsData.aboutUs.content[13].ourTeamHeader4}</h2>
            <p>{AboutUsData.aboutUs.content[13].ourTeamParagraph4} </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default About;
