import React, { useEffect } from "react";
import "./mobileDeviceManagement.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function MobileDeviceManagement() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      {" "}
      <Header></Header>
      <div className="moblieservice-banner">
        {/* <h6 className="moblieservice-style">Mobile Device Management</h6> */}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 ">
            <h2 style={{ textAlign: "left" }}>
              Device Enrollment and Provisioning
            </h2>
            <p style={{ textAlign: "left" }}>
              Empower your organization with seamless Mobile Device Management
              solutions from Nilaware. We specialize in delivering comprehensive
              MDM services designed to enhance security, streamline device
              management, and maximize productivity across your mobile
              workforce.
            </p>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="moblieservice-image2"></div>
          </div> */}
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Device Enrollment and Configuration</h4>
                  <p>
                    Simplify the onboarding process with our MDM services that
                    streamline device enrollment and configuration. We support a
                    wide range of devices, including Smartphones, Tablets, and
                    Laptops, ensuring they are securely provisioned and
                    compliant with organizational policies from day one.
                  </p>
                  <h4>Application Management</h4>
                  <p>
                    Efficiently manage and distribute Applications across your
                    mobile devices with our Application Management solutions.
                    From APP deployment and updates to license management and
                    security configurations, we ensure seamless integration and
                    control over your mobile app ecosystem.
                  </p>
                  <h4>Security and Compliance</h4>
                  <p>
                    Protect sensitive data and ensure Regulatory Compliance with
                    our robust Security and Compliance measures. We implement
                    encryption, secure containers, and compliance policies to
                    safeguard corporate information and mitigate risks
                    associated with mobile devices.
                  </p>
                  <h4>Mobile Content Management</h4>
                  <p>
                    Enable secure access and sharing of documents and content on
                    mobile devices with our Mobile Content Management solutions.
                    We facilitate secure file storage, synchronization, and
                    collaboration across devices while maintaining data
                    integrity and confidentiality.
                  </p>
                  <h4>Remote Monitoring and Support</h4>
                  <p>
                    Monitor and troubleshoot mobile devices remotely with our
                    Remote Monitoring and Support services. We provide real-time
                    visibility into device health, Performance metrics, and
                    Compliance status, enabling proactive management and rapid
                    resolution of issues.
                  </p>
                  <h4>BYOD (Bring Your Own Device) Management</h4>
                  <p>
                    Support BYOD initiatives while maintaining security and
                    control with our BYOD Management solutions. We help
                    establish policies, enforce security measures, and segregate
                    personal and corporate data on employee-owned devices to
                    protect corporate resources.
                  </p>
                  <h4>Lifecycle Management</h4>
                  <p>
                    Manage the entire device lifecycle effectively with our
                    Lifecycle Management services. From procurement and
                    provisioning to retirement and disposal, we ensure devices
                    are managed efficiently, reducing costs and enhancing
                    operational efficiency.
                  </p>
                  <h4>User Experience and Productivity</h4>
                  <p>
                    Enhance user experience and productivity with our User
                    Experience and Productivity enhancements. We optimize device
                    performance, provide user training and support, and empower
                    employees with tools and resources to maximize their mobile
                    productivity.
                  </p>
                  <h4>Strategic Consulting and Implementation</h4>
                  <p>
                    Partner with our experienced team for Strategic Consulting
                    and Implementation services. We collaborate closely with you
                    to assess your mobile device needs, develop tailored
                    strategies, and implement scalable MDM solutions that align
                    with your business objectives.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Remote Management</h4>
                  <p>
                    Configure, monitor, and troubleshoot devices remotely,
                    including device wipe, lock, and reset capabilities to
                    maintain security and data protection.
                  </p>
                  <h4>User Authentication</h4>
                  <p>
                    Implement multi-factor authentication (MFA) and access
                    controls to verify user identity and secure access to
                    corporate resources.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>{" "}
      </div>
      <div className="container">
        <div className="row">
          <h2>The Nilaware Differentiator</h2>
          <p>
            Expertise and Reliability: With a proven track record in MDM, we
            bring expertise and reliability to every project.
          </p>
          <p>
            {" "}
            Customized Solutions: We tailor solutions to meet your specific
            business requirements and industry regulations.
          </p>
          <p>
            Security and Compliance: Our focus on security and compliance
            ensures your data and devices are protected at all times.
          </p>
          <p>
            Customer Support: We provide dedicated support and proactive
            guidance to optimize your MDM environment and maximize ROI.
          </p>
          <p>
            {" "}
            Transform your mobile workforce with our comprehensive Mobile Device
            Management solutions.{" "}
          </p>
          <p>
            Contact us today to learn how we can secure, manage, and optimize
            your mobile devices to drive business success and innovation.
          </p>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Centralized Management</div>
                    <div className="overlay">
                      <p>
                        Streamline device provisioning, configuration, and
                        management processes through a centralized MDM platform,
                        reducing administrative overhead.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Enhanced Security</div>
                    <div className="overlay">
                      <p>
                        Enforce security policies (e.g., encryption, passcode
                        requirements) and compliance standards to protect
                        sensitive data on mobile devices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Improved Efficiency</div>
                    <div className="overlay">
                      <p>
                        Automate device enrolment, configuration updates, and
                        application deployments to enhance IT efficiency and
                        user productivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Compliance Assurance</div>
                    <div className="overlay">
                      <p>
                        Ensure devices comply with corporate policies,
                        regulatory requirements (e.g., GDPR, HIPAA), and
                        industry standards through consistent management and
                        monitoring.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="moblieservice-image3"></div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 service-text-container6">
            <h2 style={{ textAlign: "left" }}>Configuration Management</h2>
            <p style={{ textAlign: "left" }}>
              Configuration Management involves maintaining and updating device
              configurations, settings, and policies across mobile devices to
              ensure consistency, security, and optimal performance.
            </p>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Configuration Templates</h4>
                  <p>
                    Create and deploy configuration templates to standardize
                    device settings (e.g., email, VPN, Wi-Fi) across different
                    device types and platforms.
                  </p>
                  <h4>Policy Enforcement</h4>
                  <p>
                    Implement and enforce security policies (e.g., password
                    complexity, encryption, app restrictions) to protect
                    corporate data and mitigate security risks.
                  </p>
                  <h4>Compliance Monitoring</h4>
                  <p>
                    Monitor device compliance with configured policies and
                    settings, generating alerts and reports on non-compliant
                    devices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Automated Updates</h4>
                  <p>
                    Schedule and deploy software updates, patches, and firmware
                    upgrades to ensure devices are running the latest security
                    patches and enhancements.
                  </p>
                  <h4>Configuration Audits</h4>
                  <p>
                    Conduct regular audits and assessments of device
                    configurations to identify vulnerabilities,
                    misconfigurations, and compliance gaps.
                  </p>
                  <h4>Change Management</h4>
                  <p>
                    Manage configuration changes, updates, and rollbacks through
                    controlled processes to minimize disruptions and maintain
                    operational stability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Consistency and <br></br> Control
                    </div>
                    <div className="overlay">
                      <p>
                        Ensure uniformity and consistency in device
                        configurations and policies across the organization,
                        enhancing security and compliance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Risk<br></br> Mitigation
                    </div>
                    <div className="overlay">
                      <p>
                        Identify and mitigate configuration vulnerabilities and
                        security gaps through proactive monitoring and
                        enforcement of policies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Operational <br></br>Efficiency
                    </div>
                    <div className="overlay">
                      <p>
                        Reduce manual configuration tasks and streamline
                        management workflows through automated configuration
                        deployment and updates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Adaptability and Scalability
                    </div>
                    <div className="overlay">
                      <p>
                        Scale configuration management processes to accommodate
                        growing device fleets and evolving business requirements
                        effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4 service-text-container">
            <h2 style={{ textAlign: "left" }}>Security Management</h2>
            <p style={{ textAlign: "left" }}>
              Security Management focuses on implementing robust security
              measures and controls to protect mobile devices, applications, and
              data from unauthorized access, malware, and cyber threats.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="moblieservice-image4"></div>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Mobile Threat Defence</h4>
                  <p>
                    Implement mobile threat detection and defence mechanisms to
                    identify and mitigate security risks, including malicious
                    apps, network attacks, and device compromises.
                  </p>
                  <h4>Data Encryption</h4>
                  <p>
                    Enable device-level and application-level encryption to
                    protect sensitive data stored on mobile devices and during
                    transmission.
                  </p>
                  <h4>Identity and Access Management (IAM)</h4>
                  <p>
                    Implement IAM solutions to enforce secure authentication
                    methods (e.g., MFA, biometrics) and access controls for
                    device users.
                  </p>
                  <h4>App Security</h4>
                  <p>
                    Secure mobile applications by implementing app wrapping,
                    containerization, and app-level security policies (e.g.,
                    sandboxing, runtime application self-protection).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Device Compliance Checks</h4>
                  <p>
                    Perform regular device Compliances to ensure adherence to
                    security policies, configuration standards, and regulatory
                    requirements.
                  </p>
                  <h4>Incident Response</h4>
                  <p>
                    Develop and implement incident response plans and procedures
                    to detect, respond to, and recover from security incidents
                    affecting mobile devices.
                  </p>
                  <h4>Security Awareness Training</h4>
                  <p>
                    Provide security awareness training and education to mobile
                    device users to promote safe practices and mitigate
                    human-related security risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Data <br></br> Protection
                    </div>
                    <div className="overlay">
                      <p>
                        Safeguard sensitive data and intellectual property from
                        unauthorized access and data breaches through
                        comprehensive security measures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Threat Detection and Prevention
                    </div>
                    <div className="overlay">
                      <p>
                        Detect and mitigate mobile-specific threats and
                        vulnerabilities to protect devices and corporate
                        resources from cyber-attacks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Compliance and Governance
                    </div>
                    <div className="overlay">
                      <p>
                        Ensure mobile device security practices align with
                        regulatory requirements (e.g., GDPR, HIPAA) and industry
                        standards to avoid penalties and reputational damage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      User <br></br> Empowerment
                    </div>
                    <div className="overlay">
                      <p>
                        Empower users with secure mobile access to corporate
                        resources while educating them on security best
                        practices and potential risks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer></Footer>
    </>
  );
}
export default MobileDeviceManagement;
