import React from "react";
import "./accordion.css";

class Accordion extends React.Component {
  render() {
    const {
      title,
      expand,
      onClick,
      description,
      description2,
      description3,
      description4,
      description5,
    } = this.props;

    return (
      <div>
        <div className="card mt-4">
          <dt
            className={expand ? "title is-expanded" : "title"}
            onClick={onClick}
          >
            <div className="accordionIcon flex">
              {/* <img src={iconImg}></img> */}
            </div>
            <div className="px-2 accordion-text">{title}</div>
          </dt>
          <dd
            className={expand ? "content is-expanded" : "content"}
            onClick={onClick}
          >
            <p>
              {description}
              <br></br>
              {description2}
              <br></br>
              {description3}
              <br></br>
              {description4}
              <br></br>
              {description5}
            </p>
          </dd>
        </div>
      </div>
    );
  }
}

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block1: false,
      block2: false,
      block3: false,
    };
  }

  toggle = (index) => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  toggleExpand =
    (expand = false) =>
    () => {
      this.setState({
        block1: expand,
        block2: expand,
        block3: expand,
      });
    };

  render() {
    const accordionList = [
      {
        title: " Why Choose Nilaware IT for  Support?",
        description:
          "Discover the reasons why Nilaware IT is the top choice for businesses and organizations seeking expert  Virtualization Support.",
      },
      {
        title: "Expertise in  Virtualization ",
        description:
          " We're proud to have a team of certified   experts with extensive experience in virtualization technologies. With our in-depth knowledge and expertise,we're well-equipped to handle any  -related challenge and provide tailored solutions to meet your organization's needs.",
      },
      {
        title: "Dedicated Support Team",
        description:
          "We understand that downtime can be costly for your business, which is why we're committed to providing fast, reliable, and personalized support whenever you need it. Our dedicated support team is available around the clock to address your inquiries,troubleshoot issues, and ensure that your   environment remains up and running smoothly.",
      },
      {
        title: " Tailored Solutions",
        description:
          "We recognize that every organization is unique, with its own set of challenges, goals, and requirements. That's why we take a personalized approach to every client engagement, working closely with you to understand your specific needs and develop customized solutions that align with your business objectives.",
      },
      {
        title: " Client Success Stories/Testimonials",
        description:
          " Don't just take our word for it! Hear what our satisfied clients have to say about their experience working with Nilaware IT. Our client success stories and testimonials speak volumes about the quality of our services and the value we deliver to businesses and organizations like yours.",
      },
      {
        title: "Proven Track Record of Success ",
        description:
          "With 6 years of experience in the industry and a proven track record of success, Nilaware IT has established itself as a trusted leader in   Virtualization Support. Countless businesses and organizations have relied on us to help them maximize the performance and reliability of their   environments, and we're ready to do the same for you.",
      },
    
    ];

    return (
      <div className="container">

        <dl className="accordion">
          {accordionList.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              description={item.description}
              description2={item.description2}
              description3={item.description3}
              description4={item.description4}
              description5={item.description5}
              onClick={this.toggle(index + 1)}
              expand={this.state[`block${index + 1}`]}
            />
          ))}
        </dl>
      </div>
    );
  }
}

export default Application;
