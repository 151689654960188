import React , {  useEffect }from "react";
import "./serverStorage.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function ServerStorage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      {" "}
      <Header></Header>
      <div className="serverService-banner"></div>
      <div className="container">
        <div className="row">
          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className="serverStrorageIntro-image"></div>
          </div> */}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 service-text-container6">
            <h2 style={{ textAlign: "left" }}>Storage Services</h2>
            <p style={{ textAlign: "left" }}>
              Storage Area Network (SAN) Setup: Designing and deploying SAN
              solutions to provide centralized, high-performance storage
              accessible to multiple servers.
            </p>
            <p style={{ textAlign: "left" }}>
              Network-Attached Storage (NAS) Implementation: Installing NAS
              devices to provide file-based storage over a network, typically
              using protocols like NFS or SMB/CIFS.
            </p>
            <p style={{ textAlign: "left" }}>
              Storage Virtualization: Abstracting physical storage resources
              into a single virtualized storage pool to simplify management,
              improve utilization, and enhance scalability.
            </p>
            <p style={{ textAlign: "left" }}>
              Data Backup and Recovery Solutions: Implementing backup
              strategies, including disk-based and tape-based backups, as well
              as disaster recovery solutions to ensure data integrity and
              business continuity.
            </p>
            <p style={{ textAlign: "left" }}>
              Storage Performance Tuning: Optimizing storage systems for better
              performance through configuration adjustments, workload balancing,
              and utilization monitoring.
            </p>
            <p style={{ textAlign: "left" }}>
              Storage Consolidation and Migration: Evaluating current storage
              infrastructure, consolidating storage resources, and migrating
              data to new storage systems for efficiency and cost savings.
            </p>
            <p style={{ textAlign: "left" }}>
              Storage Security and Data Encryption: Implementing security
              measures such as access controls, encryption, and data integrity
              checks to protect stored data from unauthorized access and
              breaches.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Hypervisor Deployment</h4>
                  <p>
                    Install and configure hypervisor software (e.g., RedHat
                    OpenShift, Proxmox VE, VMware ESXi, Microsoft Hyper-V) on
                    physical servers to enable virtualization.
                  </p>
                  <h4>Virtual Machine (VM) Provisioning</h4>
                  <p>
                    Create and deploy virtual machines based on workload
                    requirements, optimizing resource allocation and
                    performance.
                  </p>
                  <h4>Resource Pooling</h4>
                  <p>
                    Aggregate server resources (CPU, memory, storage) into a
                    shared pool to maximize utilization and efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>High Availability (HA)</h4>
                  <p>
                    Implement HA configurations to ensure continuous uptime and
                    failover protection for critical applications.
                  </p>
                  <h4>Migration and Optimization</h4>
                  <p>
                    Perform VM migrations (e.g., vMotion, Live Migration) and
                    optimize VM placement to balance workload and resource
                    usage.
                  </p>
                  <h4>Performance Monitoring</h4>
                  <p>
                    Monitor VM performance metrics (CPU usage, memory
                    utilization, disk I/O) to identify and resolve performance
                    bottlenecks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div> */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Cost Optimization</div>
                    <div className="overlay">
                      <p>
                        Reduce hardware and operational costs by consolidating
                        multiple servers onto fewer physical machines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Scalability</div>
                    <div className="overlay">
                      <p>
                        Easily scale IT infrastructure to accommodate changing
                        business needs and growth without significant hardware
                        investments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Flexibility</div>
                    <div className="overlay">
                      <p>
                        Quickly deploy and manage VMs to support diverse
                        workloads and applications.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Disaster Recovery</div>
                    <div className="overlay">
                      <p>
                        Facilitate rapid recovery and business continuity
                        through VM backups and replication.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="keyActivities-layout mt-5">
        <div className="container">
          <h2 style={{ textAlign: "left" }}>Key Activities</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              {/* <div className="Installation-image"></div> */}
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Storage Array Deployment</h4>
                  <p>
                    Deploy storage arrays (SAN, NAS) and configure RAID levels
                    to ensure data redundancy and fault tolerance.
                  </p>
                  <h4>Virtual Storage Provisioning</h4>
                  <p>
                    Allocate virtual storage volumes to applications and users,
                    optimizing storage utilization and performance.
                  </p>
                  <h4>Data Tiering</h4>
                  <p>
                    Implement automated data tiering to migrate frequently
                    accessed data to high-performance storage tiers and less
                    accessed data to lower-cost storage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
              <div className="row">
                <div className="keyActivities-style">
                  <h4>Thin Provisioning</h4>
                  <p>
                    Allocate storage space dynamically to applications as
                    needed, reducing wasted storage capacity and improving
                    efficiency.
                  </p>
                  <h4>Snapshot and Backup</h4>
                  <p>
                    Capture point-in-time snapshots of data and perform regular
                    backups to protect against data loss and facilitate
                    recovery.
                  </p>
                  <h4>Performance Optimization</h4>
                  <p>
                    Monitor storage performance metrics (IOPS, throughput,
                    latency) and optimize storage configurations for optimal
                    application performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Storage Efficiency</div>
                    <div className="overlay">
                      <p>
                        Reduce storage costs and improve resource utilization
                        through centralized management and virtualization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Scalability</div>
                    <div className="overlay">
                      <p>
                        Scale storage capacity and performance non-disruptively
                        to meet growing data demands and business requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Data Protection</div>
                    <div className="overlay">
                      <p>
                        Enhance data availability and resilience with advanced
                        features like replication, mirroring, and disaster
                        recovery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Simplified Management</div>
                    <div className="overlay">
                      <p>
                        Streamline storage provisioning, monitoring, and
                        maintenance tasks through unified management interfaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">High Performance</div>
                    <div className="overlay">
                      <p>
                        Deliver high-speed data access and low-latency storage
                        capabilities for demanding applications and workloads.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Scalability</div>
                    <div className="overlay">
                      <p>
                        Scale storage infrastructure seamlessly to meet evolving
                        business needs without compromising performance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Data Protection</div>
                    <div className="overlay">
                      <p>
                        Ensure data integrity and availability through robust
                        replication, backup, and disaster recovery strategies
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">Centralized Management</div>
                    <div className="overlay">
                      <p>
                        Simplify storage administration and troubleshooting with
                        centralized management tools and automation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Business <br></br> Continuity
                    </div>
                    <div className="overlay">
                      <p>
                        Ensure uninterrupted operations and minimize downtime by
                        quickly recovering critical data and systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Data<br></br> Protection
                    </div>
                    <div className="overlay">
                      <p>
                        Protect against data loss, corruption, and cyber threats
                        with reliable backup and recovery mechanisms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Compliance and Governance
                    </div>
                    <div className="overlay">
                      <p>
                        Meet regulatory requirements and industry standards for
                        data protection, retention, and disaster recovery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div id="card-area">
              <div className="wrapper">
                <div className="box-area">
                  <div className="box-card">
                    <div className="text-content">
                      Cost <br></br> optimization
                    </div>
                    <div className="overlay">
                      <p>
                        Optimize storage resources and reduce operational costs
                        associated with data protection and recovery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default ServerStorage;
