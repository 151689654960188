import React from "react";
import "./ourBrand.css";
import { useNavigate } from "react-router-dom";
import promox from "../assets/proxmox-authorized-reseller-logo-color-300px.png";
import redHat from "../assets/Red-Hat-logo1.png";
// import VMWare from "../assets/vmware.png";
// import Nutanix from "../assets/nutanix-logo2.png";
// const animationSpeed = "40s";

// const sliderStyle = {
//   height: "100px",
//   margin: "auto",
//   overflow: "hidden",
//   position: "relative",
//   width: "auto",
// };

// const slideTrackStyle = {
//   animation: `scroll ${animationSpeed} linear infinite`,
//   display: "flex",
//   width: "calc(300px * 9)",
// };

// const slideStyle = {
//   height: "119px",
//   width: "185px",
// };

const imageAnimationStyle = {
  height: "70%",
  width: "65%",
  marginTop: "7px",
};
function OurBrands() {
  const navigate = useNavigate();

  const goToRedhat = () => {
    navigate("/solution/redhat");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const goToNutanix = () => {
  //   navigate("/solution/nutanix");
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };
  // const goToVmware = () => {
  //   navigate("/solution/vmware");
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };
  const goToProxmmox = () => {
    navigate("/solution/proxmox");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="container">
        <div className="row ">
          <h1 className="ourBrand-style  "> Our Partnerships </h1>
          <div className="col-sm-12 col-md-6 col-lg-3 col-md-6 col-lg-6 mt-4">
            {/* <div className="custom-card "> */}
            <div className="row">
              {" "}
              <img
                src={redHat}
                style={imageAnimationStyle}
                className="image-style3 "
              />
              <div style={{ padding: "4% 0%" }}>
                <button className="button-34 " onClick={() => goToRedhat()}>
                  More Information
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 col-md-6 col-lg-6  mt-4">
            {/* <div className="custom-card "> */}
            <div className="row">
              {" "}
              <img src={promox} className="image-style4 col-12" />
              <div style={{ padding: "3%" }}>
                <button
                  className="button-34 "
                  style={{ marginLeft: "-4%" }}
                  onClick={() => goToProxmmox()}
                >
                  More Information
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OurBrands;
