import React, { useEffect } from "react";
import "./whitePapers.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function WhitePapers() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="whitePapers-image">
        {" "}
        <h6 className="whitePapers-bannerStyle ">white Papers</h6>
      </div>
      <Footer></Footer>
    </>
  );
}
export default WhitePapers;
