import React from "react";
import "./footer.css";
import logo2 from "../assets/yyyy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFacebook,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLocationDot,
  faEnvelopeOpenText,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Zoom";
// import pdf from "../assets/Overseas.pdf";
function Footer() {
  // const handleClick = () => {
  //   window.open(pdf, "_blank");
  // };
  const scrollBottom = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    // [`& .${tooltipClasses.arrow}`]: {
    //   color: theme.palette.common.black,
    // },
    [`& .${tooltipClasses.tooltip}`]: {
      background: "linear-gradient(to right bottom, #ffb629, #ffda56)",
    },
  }));
  return (
    <div>
      <footer>
        <div className="container-lxf  ">
          <div className="row  mt-5">
            <div className="col-md-8">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="align-style">
                    <div>
                      <div className="para-style">
                        <Link to="/">
                          {" "}
                          <img src={logo2} className="logo-style-2"></img>
                        </Link>

                        <div className="row mt-3 pt-1">
                          <div className="col-2">
                            <a
                              href="https://youtube.com/@nilawarellc-itconsulting-d3y?si=PVHHnbA5KcuHAx0I"
                              target="_blank"
                              style={{ color: "white" }}
                            >
                              <FontAwesomeIcon
                                icon={faYoutube}
                                className="icons-style2"
                                style={{
                                  fontSize: "20px",
                                }}
                              />
                            </a>
                          </div>
                          <div className="col-2">
                            <a
                              href="https://in.linkedin.com/company/nilaware-it"
                              target="_blank"
                              style={{ color: "white" }}
                            >
                              <FontAwesomeIcon
                                icon={faLinkedin}
                                className="icons-style2"
                                style={{ fontSize: "20px" }}
                              />
                            </a>
                          </div>
                          <div className="col-2">
                            <a
                              href="https://x.com/nilawarellc"
                              target="_blank"
                              style={{ color: "black" }}
                            >
                              <FontAwesomeIcon
                                icon={faXTwitter}
                                className="icons-style2"
                                style={{ fontSize: "20px", color: "white" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="align-style">
                    <h4 className="footer__area"> Quick Links </h4>
                    {/* <span className="lin-344"></span> */}
                    <div className="para-style">
                      <Link to="/" onClick={() => scrollBottom()}>
                        <li>
                          <a href="#home">Home</a>
                        </li>
                      </Link>
                      <Link to="/about-us" onClick={() => scrollBottom()}>
                        <li>
                          <a href="#aboutUs">About Us</a>
                        </li>
                      </Link>

                      <Link to="/case-studies">
                        <li>
                          <a href="#caseStudies" onClick={() => scrollBottom()}>
                            Case Studies
                          </a>
                        </li>
                      </Link>
                      {/* <Link to="/resources/blog">
                    <li>
                      <a href="#blog" onClick={() => scrollBottom()}>
                        Resources
                      </a>
                    </li>
                  </Link> */}
                      <Link to="/contact" onClick={() => scrollBottom()}>
                        <li>
                          <a href="#contact">Contact</a>
                        </li>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="align-style">
                    <h4 className="footer__area"> Services </h4>
                    {/* <span className="lin-344"></span> */}
                    <div className="para-style">
                      <Link
                        to="/services/core-infrastructure"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#redhat"> Core Infrastructure Services </a>
                        </li>
                      </Link>
                      <Link
                        to="/services/operation-support"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#proxmox">Operation & Support Services </a>
                        </li>
                      </Link>
                      <Link
                        to="/services/end-user-computing"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#nutanix">End User Computing Services </a>
                        </li>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="align-style">
                    <h4 className="footer__area"> Solutions </h4>
                    {/* <span className="lin-344"></span> */}
                    <div className="para-style">
                      <Link
                        to="/solution/redhat"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#redhat"> RedHat </a>
                        </li>
                      </Link>
                      <Link
                        to="/solution/vmware"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#vmware"> VMware</a>
                        </li>
                      </Link>
                      <Link
                        to="/solution/nutanix"
                        onClick={() => scrollBottom()}
                      >
                        <li>
                          <a href="#nutanix">Nutanix </a>
                        </li>
                      </Link>
                      <Link to="/solution/proxmox">
                        <li>
                          <a href="#proxmox" onClick={() => scrollBottom()}>
                            Proxmox
                          </a>
                        </li>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="align-style">
                    <h4 className="footer__area"> Contact us </h4>
                    <div className="para-style">
                      <p>
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ fontSize: "15px" }}
                        />
                        &nbsp;Nilaware LLC
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faEnvelopeOpenText}
                          style={{ fontSize: "15px" }}
                        />{" "}
                        info@nilaware.com
                      </p>
                      <p>
                        {" "}
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{ fontSize: "15px" }}
                        />
                        &nbsp;+1 (424) 339 3282
                      </p>
                    </div>
                    <BootstrapTooltip
                      // title={
                      //   <h6 style={{ color: "black", marginTop: "5px" }}>
                      //     Return To Top
                      //   </h6>
                      // }
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                marginTop: "20px",
                                marginLeft: "220px",
                              },
                          },
                        },
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{
                          fontSize: "12px",
                          marginTop: "20px",
                          color: "white",
                          marginLeft: "10%",
                        }}
                        className="arrow-style1"
                        onClick={() => scrollBottom()}
                      />{" "}
                    </BootstrapTooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRights">
          <h5 className="copyRights-style">
            Copyright © 2024 Nilaware LLC - All Rights Reserved.
          </h5>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
