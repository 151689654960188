import React, { useEffect } from "react";
import "./nutanix.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function Nutanix() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="solutionNutanix-bannerImage "></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <h2 className="header-style">
              Hyperconverged Infrastructure with Nutanix
            </h2>
            <p style={{ textAlign: "left" }}>
              Optimize your IT environment with Nutanix’s hyperconverged
              infrastructure, designed for efficiency and scalability. Nilaware
              IT’s Nutanix solutions include:
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="solutionNutanix-image "></div>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <h2 style={{ textAlign: "left" }}>
          {" "}
          Nilaware IT’s Nutanix solutions include.
        </h2>
        <div className="row mt-5 mb-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="service-card">
              <h3>Simplified Management</h3>
              <p>
                Centralize management of compute, storage, and networking
                through Nutanix Prism.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-5 ">
            <div class="service-card">
              <h3>Performance</h3>
              <p>
                Enhance performance with Nutanix’s high-speed storage and
                optimized resource utilization.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4   mt-5">
            <div class="service-card">
              <h3>Scalability</h3>
              <p>
                Easily scale out by adding nodes without disrupting operations.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-5">
            <div class="service-card">
              <h3>Data Protection</h3>
              <p>
                Implement robust data protection and disaster recovery
                solutions.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4   mt-5">
            <div class="service-card">
              <h3>Hybrid Cloud</h3>
              <p>
                Seamlessly integrate on-premises infrastructure with public
                cloud services.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4  mt-5">
            <div class="service-card">
              <h3> Integration Capabilities</h3>
              <p>
                Seamlessly integrates within Nutanix's ecosystem, empowering
                efficient management and scalability of virtualized
                infrastructure and applications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="background-overlay">
        <div className="container mt-5">
          <h4>
            Leverage the full potential of Nutanix with Nilaware IT’s expert
            deployment and management services.
          </h4>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
export default Nutanix;
