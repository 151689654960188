import React, { useEffect } from "react";
import "./redhat.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
function RedHat() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="solutionrehat-banner"></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 ">
            <h2 className="header-style">
              Enterprise-Grade Virtualization with RedHat
            </h2>
            <p style={{ textAlign: "left" }}>
              Leverage the power and reliability of RedHat Virtualization to
              enhance your IT infrastructure. Nilaware IT offers comprehensive
              solutions to deploy, manage, and optimize RedHat Virtualization
              environments, ensuring your business benefits from:
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="redHatDecription-image"></div>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <h2 style={{ textAlign: "left" }}> </h2>
        <div className="row mt-5 mb-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>High Performance</h3>
              <p>
                Achieve superior performance with optimized resource allocation
                and high availability.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Scalability</h3>
              <p>
                Easily scale your virtual environment to meet growing business
                demands.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
            <div class="service-card">
              <h3>Security</h3>
              <p>
                Implement robust security measures to protect your virtual
                machines and data.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>Management</h3>
              <p>
                Utilize RedHat Virtualization Manager for centralized and
                simplified management.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>Support</h3>
              <p>
                Access expert support and maintenance services to ensure your
                environment runs smoothly.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3> Integration Capabilities</h3>
              <p>
                Seamlessly integrates with existing IT environments to maximize
                operational efficiency and flexibility.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="background-overlay">
        <div className="container mt-5">
          <h4>
            Transform your IT operations with Nilaware IT’s RedHat
            Virtualization solutions, designed for reliability and efficiency
          </h4>
          {/* <h4>
            For more Information related to RedHat Download E book &nbsp;
            <button className="button-34" onClick={handleModal}>
              {" "}
              click Here
            </button>
          </h4> */}
        </div>
      </div>
      {/* {showModal && <Modal setShowModal={setShowModal}></Modal>} */}
      <Footer></Footer>
    </>
  );
}
export default RedHat;
