import React, { useState, useEffect } from "react";
import logo from "../assets/nilaware- logo-1.jpg";
import { Link } from "react-router-dom";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown, Nav } from "react-bootstrap";
import "react-bootstrap-submenu/dist/index.css";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import {
  faTimes,
  faBars,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function Header() {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenu1, setShowServiceMenu] = useState(false);
  const [showSubmenu2, setShowServiceMenu2] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [showCoreSubmenu, setShowCoreSubmenu] = useState(false);
  const [showEndSubmenu, setShowEndSubmenu] = useState(false);
  const [showOperationSubmenu, setShowOperationSubmenu] = useState(false);
  // const [showSubDropdown, setShowSubDropdown] = useState(false);

  const menuListEnter = () => {
    setShowSubmenu(true);
  };
  const menuListLeave = () => {
    setShowSubmenu(false);
  };

  const handleServiceMenuEnter = () => {
    setShowServiceMenu(true);
  };

  const handleServiceMenuLeave = () => {
    setShowServiceMenu(false);
  };
  const handleServiceMenuEnter2 = () => {
    setShowServiceMenu2(true);
  };

  const handleServiceMenuLeave2 = () => {
    setShowServiceMenu2(false);
  };
  const handleCoreMenuEnter = () => {
    setShowCoreSubmenu(true);
  };
  const handleCoreMenuLeave = () => {
    setShowCoreSubmenu(false);
  };
  const handleEndMenuEnter = () => {
    setShowEndSubmenu(true);
  };
  const handleEndMenuLeave = () => {
    setShowEndSubmenu(false);
  };

  const handleOperationMenuEnter = () => {
    setShowOperationSubmenu(true);
  };
  const handleOperationMenuLeave = () => {
    setShowOperationSubmenu(false);
  };
  // const handleToggle = (isOpen) => {
  //   setShowSubDropdown(isOpen);
  // };

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollBottom = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header>
        <Link to="/">
          {" "}
          <div className="logo">
            <img src={logo} alt="logo" className="logo-style"></img>
          </div>
        </Link>

        {isMobileView && (
          <button className="mobile-toggler" onClick={toggleMobileMenu}>
            <FontAwesomeIcon
              icon={isMobileMenuOpen ? faTimes : faBars}
              style={{ fontSize: "30px" }}
              className="toggler-style"
            />
          </button>
        )}
        <nav>
          <Link to="/" activeClassName="active">
            <h3 className="header-style-top " onClick={() => scrollBottom()}>
              HOME{" "}
            </h3>
          </Link>
          <Link to="/about-us">
            <h3 className="header-style-top" onClick={() => scrollBottom()}>
              ABOUT US{" "}
            </h3>
          </Link>

          <a
            className="link-style link-lkj nav-link-syc"
            onMouseEnter={handleServiceMenuEnter}
            onMouseLeave={handleServiceMenuLeave}
          >
            <h3 onClick={() => navigate("")}>Services</h3>
            <ol className={"submenu" + (showSubmenu1 ? " show" : "")}>
              <Link
                to="/services/core-infrastructure"
                className="link-lkj nav-link-syc"
                onMouseEnter={handleCoreMenuEnter}
                onMouseLeave={handleCoreMenuLeave}
                onClick={() => scrollBottom()}
              >
                CORE INFRASTRUCTURE SERVICES &nbsp;
                <FontAwesomeIcon icon={faCaretRight} />
                <ol className={"subMenu3" + (showCoreSubmenu ? " show" : "")}>
                  <Link
                    to="/services/core-infrastructure/network-services"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Network Services
                  </Link>
                  <Link
                    to="/services/core-infrastructure/storage-services"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Storage Services
                  </Link>
                  <Link
                    to="/services/core-infrastructure/virtualization-services"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Server Virtualization Services
                  </Link>
                  <Link
                    to="/services/core-infrastructure/cloud-services"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Cloud Services
                  </Link>
                </ol>
              </Link>
              <Link
                to="/services/end-user-computing"
                className="link-lkj2 nav-link-syc2"
                onMouseEnter={handleEndMenuEnter}
                onMouseLeave={handleEndMenuLeave}
                onClick={() => scrollBottom()}
              >
                END USER COMPUTNG SERVICES &nbsp;
                <FontAwesomeIcon icon={faCaretRight} />
                <ol className={"subMenu4" + (showEndSubmenu ? " show" : "")}>
                  <Link
                    to="/services/end-user-computing/desktop-Virtualization"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Desktop Virtualization
                  </Link>
                  <Link
                    to="/services/end-user-computing/moblie-device-management"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Mobile Device Management
                  </Link>
                </ol>
              </Link>
              <Link
                to="/services/operation-support"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
                onMouseEnter={handleOperationMenuEnter}
                onMouseLeave={handleOperationMenuLeave}
              >
                OPERATION AND SUPPORT SERVICES &nbsp;
                <FontAwesomeIcon icon={faCaretRight} />
                <ol
                  className={"subMenu3" + (showOperationSubmenu ? " show" : "")}
                >
                  <Link
                    to="/services/operation-support/monitoring-management"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Monitoring & Management
                  </Link>
                  <Link
                    to="/services/operation-support/collaboration-communition"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Collaboration Services
                  </Link>
                  <Link
                    to="/services/operation-support/compliance-governance"
                    onClick={() => scrollBottom()}
                    className="link-lkj2 nav-link-syc2"
                  >
                    Compliance & Governance Services
                  </Link>
                </ol>
              </Link>{" "}
            </ol>
          </a>

          <a
            className="link-style link-lkj nav-link-syc"
            onMouseEnter={menuListEnter}
            onMouseLeave={menuListLeave}
          >
            <h3 onClick={() => navigate("")}>Solutions</h3>
            <ol className={"subMenu" + (showSubmenu ? " show" : "")}>
              <Link
                to="/solution/redhat"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                RedHat
              </Link>
              <Link
                to="/solution/vmware"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                VMware
              </Link>
              <Link
                to="/solution/nutanix"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                Nutanix
              </Link>
              <Link
                to="/solution/proxmox"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                Proxmox
              </Link>
            </ol>
          </a>

          <Link to="/case-studies">
            <h3 className="header-style-top " onClick={() => scrollBottom()}>
              CASE STUDIES{" "}
            </h3>
          </Link>
          <a
            className="link-style link-lkj nav-link-syc"
            onMouseEnter={handleServiceMenuEnter2}
            onMouseLeave={handleServiceMenuLeave2}
          >
            <h3 onClick={() => navigate("")}>Resources</h3>
            <ol className={"subMenu2" + (showSubmenu2 ? " show" : "")}>
              <Link
                to="/resources/blogs"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                Blogs
              </Link>
              <br></br>
              <Link
                to="/resources/faq"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                FAQ
              </Link>
              <br></br>
              {/* <Link
                to="/resources/white-papers"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                White Papers
              </Link>
              <Link
                to="/resources/eBook"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                E-books
              </Link>
              <Link
                to="/resources/Webinars"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                Webinars
              </Link> */}
              {/* <Link
                to="/resources/video"
                onClick={() => scrollBottom()}
                className="link-lkj nav-link-syc"
              >
                Videos
              </Link> */}
            </ol>
          </a>
          <Link to="/contact">
            <h3 className="header-style-top" onClick={() => scrollBottom()}>
              CONTACT US{" "}
            </h3>
          </Link>
        </nav>
      </header>
      {isMobileView && (
        <div
          className={`container-8dw pt-5 py-2 ${
            !isMobileMenuOpen ? "toggler-hidden" : ""
          }`}
        >
          <nav
            className={` menu-ezq menu-gre header-style-left ${
              isMobileMenuOpen ? "mobile-menu-visible" : "toggler-hidden"
            }`}
          >
            <Link to="/">
              <li>
                <a href="#home">HOME </a>
              </li>
            </Link>
            <div className="menu-o2n"></div>
            <Link to="/about-us">
              <li>
                <a href="#about">ABOUT US </a>
              </li>
            </Link>
            <div className="menu-o2n"></div>
            <NavDropdownMenu
              title="SERVICES"
              id="collasible-nav-dropdown"
              className="subdropdown-text"
            >
              <DropdownSubmenu
                as={Link}
                to="/services/core-infrastructure"
                title=" CORE INFRASTRUCTURE SERVICES"
                className="subdropdown1"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/services/core-infrastructure/network-services"
                  className="subdropdown2"
                >
                  {" "}
                  Network Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/core-infrastructure/storage-services"
                  className="subdropdown2"
                >
                  {" "}
                  Server & Storage Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/core-infrastructure/virtualization-services"
                  className="subdropdown2"
                >
                  {" "}
                  Sever Virtualization
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/core-infrastructure/cloud-services"
                  className="subdropdown2"
                >
                  {" "}
                  Cloud Services
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu
                title="End User Computing SERVICES"
                className="subdropdown1"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/services/end-user-computing/desktop-Virtualization"
                >
                  {" "}
                  Desktop Virtualization
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/end-user-computing/moblie-device-management"
                >
                  Mobile Device Management
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu
                title="OPERATION AND SUPPORT SERVICES"
                className="subdropdown1"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/services/operation-support/monitoring-management"
                >
                  Monitoring & Management
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/operation-support/collaboration-communition"
                >
                  {" "}
                  Collaboration Services{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/services/operation-support/compliance-governance"
                >
                  {" "}
                  Compliance & Governance Services{" "}
                </NavDropdown.Item>
              </DropdownSubmenu>
            </NavDropdownMenu>
            <div className="menu-o2n"></div>
            <Dropdown className="link-lkj nav-link-syc dropdown-padding">
              <Dropdown.Toggle className="header-style">
                <Link to="" className="dropdown-text">
                  SOLUTIONS
                </Link>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ background: "none", border: "none" }}>
                <Dropdown.Item className="dropdown-style">
                  <Link to="/solution/redhat" className="link-lkj nav-link-syc">
                    RedHat
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className=" dropdown-style">
                  <Link to="/solution/vmware" className="link-lkj nav-link-syc">
                    VMware
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className=" dropdown-style">
                  <Link
                    to="/solution/nutanix"
                    className="link-lkj nav-link-syc"
                  >
                    Nutanix
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className=" dropdown-style">
                  <Link
                    to="/solution/proxmox"
                    className="link-lkj nav-link-syc"
                  >
                    Proxmox
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link to="/case-studies">
              <li>
                <a href="#case-studies">CASE STUDIES </a>
              </li>
            </Link>
            <Dropdown className="link-lkj nav-link-syc dropdown-padding">
              <Dropdown.Toggle className="header-style">
                <Link to="" className="dropdown-text">
                  RESOURCES
                </Link>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ background: "none", border: "none" }}>
                <Dropdown.Item className="dropdown-style">
                  <Link to="/resources/blogs" className="link-lkj nav-link-syc">
                    Blog
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-style">
                  <Link to="/resources/faq" className="link-lkj nav-link-syc">
                    FAQ
                  </Link>
                </Dropdown.Item>
                {/* <Dropdown.Item className=" dropdown-style">
                  <Link
                    to="/resources/white-papers"
                    className="link-lkj nav-link-syc"
                  >
                    White Papers
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className=" dropdown-style">
                  <Link to="/resources/eBook" className="link-lkj nav-link-syc">
                    E-books
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className=" dropdown-style">
                  <Link
                    to="/resources/Webinars"
                    className="link-lkj nav-link-syc"
                  >
                    Webinars
                  </Link>
                </Dropdown.Item> */}
                {/* <Dropdown.Item className=" dropdown-style">
                  <Link to="/resources/video" className="link-lkj nav-link-syc">
                    Videos
                  </Link>
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>

            <Link to="/contact">
              <li>
                <a href="#contact">CONTACT US </a>
              </li>
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
}
export default Header;
