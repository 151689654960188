import React, { useEffect } from "react";
import "./blog.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import envionment from "../assets/virtual-environment.jpg";
import dataCentre from "../assets/banners/Cloud Data Storage.jpg";
import virtual from "../assets/banners/virutualIntro.jpg";
import cosetEffective from "../assets/banners/costEffective.jpg";
// import Application from "../accordion/accordion";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
function Blog() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const goToblog1 = () => {
    navigate("/resources/blog/blog1");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog2 = () => {
    navigate("/resources/blog/blog2");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog3 = () => {
    navigate("/resources/blog/blog3");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog4 = () => {
    navigate("/resources/blog/blog4");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Header></Header>

      {/* <div className="card">
        {" "}
        <div className="container mt-5">
          <div className="row">
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <div className="img-card">
                <div className="container ">
                  <div className="card-content">
                    <div className="card-image">
                      <img src={envionment} />
                    </div>

                  
                    <p className="card-text paragraph-style">
                      How to Successfully Migrate to a Virtualized Environment:
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <div className="img-card">
                <div className="container">
                  <div className="card-content">
                    <div className="card-image">
                      <img src={smbs} />
                    </div>
                
                    <p className="card-text paragraph-style">
                      Cost-Effective Virtualization for SMBs: Transitioning to
                      RedHat and Proxmox Hypervisors
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="blog-banneerImage ">
        {/* <h6 className="blog-style" > <mark>Blog</mark> </h6> */}
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5">
            <div className="card-container">
              <div className="card-content">
                <div className="card-image">
                  <img
                    src={envionment}
                    style={{ width: "100%", height: "230px" }}
                  />
                </div>

                <p className="mt-4">
                  Optimizing IT Infrastructure for Business Agility: A CTO’s
                  Guide
                </p>
                <button
                  className="button-34"
                  style={{ margin: "0% 26%" }}
                  onClick={() => goToblog1()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container">
              <div className="card-content">
                <div className="card-image">
                  <img
                    src={dataCentre}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>

                <p className=" mt-2">
                  The Importance of Data Centre Refresh in Organizations in the
                  USA
                </p>
                <button
                  className="button-34"
                  style={{ margin: "0% 26%" }}
                  onClick={() => goToblog2()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container">
              <div className="card-content">
                <div className="card-image">
                  <img
                    src={virtual}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>

                <p className=" mt-2">
                  How to Successfully Migrate to a Virtualized Environment
                </p>
                <button
                  className="button-34"
                  style={{ margin: "0% 26%" }}
                  onClick={() => goToblog3()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container">
              <div className="card-content">
                <div className="card-image">
                  <img
                    src={cosetEffective}
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>

                <p className=" mt-2">
                  Cost-Effective Virtualization for SMBs: Transitioning to
                  RedHat and Proxmox Hypervisors
                </p>
                <button
                  className="button-34"
                  style={{ margin: "0% 26%" }}
                  onClick={() => goToblog4()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
export default Blog;
