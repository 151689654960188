import React, { useRef, useEffect } from "react";
import "./video.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// import redhat from "../assets/banners/Red_Hat.mp4";
// import proxmax from "../assets/banners/Proxmox.mp4";
// import Vmware from "../assets/banners/vmware.mp4";
// import Nutanix from "../assets/banners/Nutanix.mp4";
function Video() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const videoRefs = useRef([]);
  useEffect(() => {
    const handlePlay = (index) => {
      videoRefs.current.forEach((video, i) => {
        if (i !== index && video) {
          video.pause();
        }
      });
    };

    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.addEventListener("play", () => handlePlay(index));
      }
    });

    return () => {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          video.removeEventListener("play", () => handlePlay(index));
        }
      });
    };
  }, []);
  return (
    <>
      <Header></Header>
      <div className="video-image"></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-5">
            <div className="card-container1">
              <div className="card-content">
                <div className="card-video">
                  {/* <div className="video-banner">
                    <video
                      ref={(el) => (videoRefs.current[0] = el)}
                      width="100%"
                      className="video-style"
                      autoPlay
                      controls
                      loop
                    >
                      <source src={redhat} type="video/mp4" />
                    </video>
                  </div> */}
                </div>

                <p className="mt-4">RedHat Virtualization</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container1">
              <div className="card-content">
                <div className="card-video">
                  {/* <div className="video-banner">
                    <video
                      ref={(el) => (videoRefs.current[1] = el)}
                      width="100%"
                      className="video-style"
                      autoPlay
                      controls
                      loop
                    >
                      <source src={proxmax} type="video/mp4" />
                    </video>
                  </div> */}
                </div>

                <p className=" mt-2">Proxmox Virtualization</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container1">
              <div className="card-content">
                <div className="card-video">
                  {/* <div className="video-banner">
                    <video
                      width="100%"
                      ref={(el) => (videoRefs.current[2] = el)}
                      className="video-style"
                      controls
                      loop
                    >
                      <source src={Nutanix} type="video/mp4" />
                    </video>
                  </div> */}
                </div>

                <p className=" mt-2">Nutanix Virtualization</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6  mb-5">
            <div className="card-container1">
              <div className="card-content">
                <div className="card-video">
                  {/* <div className="video-banner">
                    <video
                      width="100%"
                      ref={(el) => (videoRefs.current[3] = el)}
                      className="video-style"
                      autoPlay
                      controls
                      loop
                    >
                      <source src={Vmware} type="video/mp4" />
                    </video>
                  </div> */}
                </div>

                <p className=" mt-2">VMware Healthcare</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default Video;
