import "./description.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCommentDots,
  faPeopleGroup,
  faThumbsUp,
  faHeadset,
  faClipboard,
  faMoneyCheckDollar,
  faCircleCheck,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";
import { faWpexplorer } from "@fortawesome/free-brands-svg-icons";
import OurBrands from "../ourBrands/ourBrand";
import Homeslider from "../homeslider/homeslider";
import homeData from "../data/data.json";
import HomeIntro from "../homeIntro/homeintro";
function Description() {
  const navigate = useNavigate();
  const goToServices = () => {
    navigate("/services");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToSContact = () => {
    navigate("/contact");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goToCoreInfrastucture = () => {
    navigate("/services/core-infrastructure");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToEnduser = () => {
    navigate("/services/end-user-computing");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToOperation = () => {
    navigate("/services/operation-support");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Homeslider></Homeslider>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            {" "}
            <p className="intro-style">
              {" "}
              Welcome to Nilaware, your trusted partner in comprehensive
              Infrastructure services for businesses and organizations.We
              specialize in delivering robust solutions that optimize and
              streamline your IT Infrastructure.<br></br>
              <br></br>
              With a deep understanding of modern IT demands, we offer a wide
              range of services designed to enhance your operational efficiency
              and support your growth. Our expertise spans across Networking,
              Storage Solutions, Cloud Integration, and Virtualization
              technologies including RedHat, Nutanix, VMware, and Proxmox.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div>
              <div>
                <div className="homeintro-image"></div>
              </div>
            </div>
          </div>
        </div>

    
      </div>
      <HomeIntro></HomeIntro>
      <div className="service-layout">
        <div className="container ">
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <div className="service-card-text">
                <h2>Our Virtualization Support Services</h2>
                <p>
                  Explore our comprehensive range of Virtualization Support
                  Services designed to meet your business's specific needs and
                  requirements.
                </p>
              </div>
            </div> */}

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="services-colorcard ">
                <h5>CORE INFRASTRUCTURE SERVICES</h5>

                <button
                  className="button-style"
                  onClick={() => goToCoreInfrastucture()}
                >
                  Read More{" "}
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="services-card ">
                <h5>END USER COMPUTING (EUC) SERVICES</h5>

                <button className="button-style" onClick={() => goToEnduser()}>
                  Read More{" "}
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
              <div className="services-colorcard ">
                <h5>OPERATION AND SUPPORT SERVICES</h5>

                <button
                  className="button-style"
                  onClick={() => goToOperation()}
                >
                  Read More{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurBrands></OurBrands>

      <div className=" whyUs-layer ">
        <div className=" container ">
          <h1 className=" whyUs-header"> The Nilaware Differentiators</h1>

          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <FontAwesomeIcon className="whyUs-icon" icon={faWpexplorer} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[0].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[0]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faPeopleGroup} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[1].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {" "}
                {
                  homeData.whychooseus.WhyChooseUsContent[1]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faCommentDots} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[2].whychooseusHeader}{" "}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[2]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faThumbsUp} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[3].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[3]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faHeadset} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[4].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[4]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faClipboard} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[5].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[5]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon
                className="whyUs-icon"
                icon={faMoneyCheckDollar}
              />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[6].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[6]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faCircleCheck} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[7].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[7]
                    .whychooseusParagraph
                }
              </p>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
              <FontAwesomeIcon className="whyUs-icon" icon={faBusinessTime} />
              <h2 style={{ color: "white" }}>
                {homeData.whychooseus.WhyChooseUsContent[8].whychooseusHeader}
              </h2>
              <p style={{ color: "white" }}>
                {
                  homeData.whychooseus.WhyChooseUsContent[8]
                    .whychooseusParagraph
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" getInTouch-layer">
        <div className="container ">
          <h2>
            Experience the Nilaware difference. Let us be your trusted partner
            in IT Infrastructure Management, driving your business towards
            greater Efficiency, Security, and Success.
            {/* <button className="button-34" onClick={() => goToServices()}>
              Click Here <FontAwesomeIcon icon={faArrowRight} />
            </button> */}
          </h2>
        </div>
      </div>
      <div className=" service-bar">
        <div className="container">
          <h1>Talk to us today</h1>
          <p>
            Ready to take your Environment to the next level? Contact us today
            to discuss your Support Needs and how we can help you Achieve your
            Goals. Our team is standing by to assist you every step of the way.
          </p>

          <button
            className="button-34"
            style={{ fontSize: "22px" }}
            onClick={() => goToSContact()}
          >
            Contact us <FontAwesomeIcon icon={faArrowRight} />{" "}
          </button>
        </div>
      </div>
    </>
  );
}
export default Description;
