import React, { useEffect } from "react";
import Header from "./header/header";
import Description from "./description/description";
import Footer from "./footer/footer";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <main>
        <Header></Header>
        <Description></Description>
        <Footer></Footer>
      </main>
    </div>
  );
}
export default Home;
