import React, {  useEffect } from "react";
import "./contact.css";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../header/header";
import Footer from "../footer/footer";
// import Multiselect from "multiselect-react-dropdown";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function Contact() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  // const [menuOpen, setMenuOpen] = useState(false);

  // const [yourName, setYourName] = useState("");
  // const [yourEmail, setYourEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [subject, setSubject] = useState("");
  // const [yourMessage, setYourMessage] = useState("");
  // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // const [selectedValues, setSelectedValues] = useState([]);

  // const options = [
  //   { cat: "Group 1", key: "RedHat Virtualization" },
  //   { cat: "Group 1", key: "Proxmox Virtualization" },
  //   { cat: "Group 1", key: "Nutanix Virtualization" },
  //   { cat: "Group 2", key: " VMWare Virtualization" },
  //   { cat: "Group 2", key: "Others" },
  // ];
  // const handleSelectionChange = (selectedItems) => {
  //   setSelectedValues(selectedItems);
  // };
  // const menuToggle = () => {
  //   setMenuOpen(!menuOpen);
  // };

  // async function submitForm() {
  //   if (yourName.length == 0) {
  //     alert("Enter Your Name ");
  //     return;
  //   }
  //   if (phoneNumber.length < 10 || isNaN(phoneNumber)) {
  //     alert("Enter a valid  phone number");
  //     return;
  //   }
  //   if (!emailPattern.test(yourEmail)) {
  //     alert("Enter a valid email address");
  //     return;
  //   }
  //   if (subject.length == 0) {
  //     alert("Enter Your subject");
  //     return;
  //   }
  // }
  return (
    <div>
      <Header></Header>

      <div className="contact-image">
        <h1 className="contact-style "></h1>
      </div>
      <div className="getIntouch">
        <div className="container mt-5 mb-5">
          <h1>Get In Touch</h1>
          <div className="row custom-card-contact mt-5">
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className=" row ">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="font-icon-style  "
                  />{" "}
                  &nbsp;
                  <h5 className="">
                    TEL:{" "}
                    <span style={{ color: "darkblue" }}>+1 (424) 339 3282</span>{" "}
                  </h5>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="   row">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="font-icon-style"
                  />
                  &nbsp;
                  <h5>
                    <span style={{ color: "darkblue" }}>info@nilaware.com</span>
                  </h5>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className=" row ">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="font-icon-style"
                  />
                  &nbsp;
                  <h5>
                    {" "}
                    <span style={{ color: "darkblue" }}>
                      {" "}
                      Nilaware LLC<br></br> 28023 Ridgebrook Ct,<br></br> Rancho
                      Palos Verdes<br></br>
                      CA 90275
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99277.4561272101!2d-118.45857944038887!3d33.71682498751114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd4b0028d0f847%3A0x129ec2c5d361a7ec!2sNilaware%20LLC!5e0!3m2!1sen!2sin!4v1720443591179!5m2!1sen!2sin"
                width="100%"
                height="400"
                // style="border:0;"
                // allowfullscreen=""
                loading="lazy"
                // referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

     

      <Footer></Footer>
    </div>
  );
}
export default Contact;
